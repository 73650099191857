export default {
    services: {
        "com.apple.signIn": {
            clientId: 'sh.surge.test-omniglot-live-react'
        },
        'com.google.signIn': {
            clientId: "88470057856-br89nurqdli6ll2aibkah94tdonnu9pa.apps.googleusercontent.com"
        }
    }
};
