import React from 'react';
import { makeStyles } from '@material-ui/core';
import Hammer from 'hammerjs';
import ReactHammer from 'react-hammerjs';

import * as Asset from './Asset';

// inspired by:
// https://medium.com/@simonepm/build-a-full-featured-tinder-like-carousel-in-vanilla-javascript-part-i-44ca3a906450

export function StaticDemo(props: {
    pixelSize: {
        width: number,
        height: number
    },
}) {
    const board = React.useRef<HTMLDivElement>(null);
    const [ height, setHeight ] = React.useState<number>(0);
    const [ width, setWidth ] = React.useState<number>(0);
    const [ index, setIndex ] = React.useState<number>(0);

    const assets = [
        { 
            pixelSize: {
                width: 5792,
                height: 8688
            },
            image: { url: '/images/feed_samples/shutterstock_551987620.jpg' },
            handle: '@carla1674',
            title: 'Thought this was cute, might delete it later ...'
        },
        {
            pixelSize: {
                width: 3648,
                height: 5472
            },
            image: { url: '/images/feed_samples/shutterstock_1781009279.jpg' },
            handle: '@mia0414',
            title: 'Night out with the bae'
        },
        { 
            pixelSize: {
                width: 3546,
                height: 5319
            },
            image: { url: '/images/feed_samples/shutterstock_1918687370.jpg' },
            handle: '@tara682',
            title: 'He hated it, so I kept it'
        }
    ];
    React.useEffect(() => {
        setHeight(board.current?.parentElement?.clientHeight || 0);
        setWidth(board.current?.parentElement?.clientWidth || 0);
    }, [ board.current ]);
      
    const useStyles = makeStyles((theme) => ({
        board: {
            width: '100%',
            height: '100%',
            position: 'relative',
            overflow: 'hidden'
        },
        deck: {
            width: '100%',
            height: '100%',
            position: 'relative',
            overflow: 'hidden'
        },
        current: {
            zIndex: 10,
            position: 'absolute',
            height: '100%',
            width: '100%',
            top: '50%',
            left: '50%',
            borderRadius: '1%',
            boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.1)',
            transform: 'translateX(-50%) translateY(-50%)'
        },
        next: {
            position: 'absolute',
            height: '100%',
            width: '100%',
            top: '50%',
            left: '50%',
            borderRadius: '1%',
            boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.1)',
            transform: 'translateX(-50%) translateY(-50%)'
        }
    }));
    const classes = useStyles();
    let isPanning: boolean = false;
    let startPos: undefined|{ x: number, y: number};
    let isDraggingFrom: undefined|number;

    const nextImage = () => {
        setIndex(_index => _index === assets.length - 1 ? 0 : _index + 1);
    }
    const handleOnPan = (e: any) => {
        const elements = {
            board: board.current?.children[0],
            topCard: board.current?.children[0].querySelector('#current') as HTMLDivElement,
        };
        if(!elements.topCard || !elements.board) {
            console.log('PAN event ignored because HTML element references are not resolved');
            return;
        }
        if (!isPanning) {          
            console.log(`PAN event triggers start of panning`);
            isPanning = true
          
            // remove transition property
            elements.topCard.style.transition = ''
          
            // get card coordinates in pixels
            let style = window.getComputedStyle(elements.topCard)
            let mx = style.transform.match(/^matrix\((.+)\)$/)
            startPos = {
                x: mx ? parseFloat(mx[1].split(', ')[4]) : 0,
                y: mx ? parseFloat(mx[1].split(', ')[5]) : 0
            };
          
            // get card bounds
            let bounds = elements.topCard.getBoundingClientRect()
            
            // get finger position on card, top (1) or bottom (-1)
            isDraggingFrom =
                (e.center.y - bounds.top) > elements.topCard.clientHeight / 2 ? -1 : 1;      

            console.log(`PAN event triggers start of panning at ${JSON.stringify(startPos)} angle ${isDraggingFrom}`);
        }
        if(!startPos || isDraggingFrom === undefined) {
            console.log('PAN event aborted early as start position and isDraggingFrom are not both defined');
            return;        
        }

        // get new coordinates
        let posX = e.deltaX + startPos.x
        let posY = e.deltaY + startPos.y
        
        // get ratio between swiped pixels and the axes
        let propX = e.deltaX / width;
        let propY = e.deltaY / height;
        
        // get swipe direction, left (-1) or right (1)
        let dirX = e.deltaX < 0 ? -1 : 1;
        let dirY = e.deltaY < 0 ? -1 : 1;
        let horizontal = Math.abs(e.deltaX) > Math.abs(e.deltaY);
        let vertical = Math.abs(e.deltaY) >= Math.abs(e.deltaX);
        
        // get degrees of rotation (between 0 and +/- 45)
        let deg = isDraggingFrom * dirX * Math.abs(propX) * 45
        
        // move and rotate card
        console.log(`PAN event moves to ${posX},${posY} and rotates card to ${deg}`);
        elements.topCard.style.transform =
          'translateX(' + posX + 'px) translateY(' + posY + 'px) rotate(' + deg + 'deg)'
        
        if (e.isFinal) {          
            isPanning = false
            
            // set back transition property
            elements.topCard.style.transition = 'transform 500ms ease-out'

            console.log('PAN event finished');

            // check threshold
            if (propX > 0.25 && e.deltaX > 0 && horizontal) {            
                // get right border position
                posX = elements.board.clientWidth

                // throw card towards the right border
                elements.topCard.style.transform =
                    'translateX(' + posX + 'px) translateY(' + posY + 'px) rotate(' + deg + 'deg)'
                console.log('PAN event yeet RIGHT');
                elements.topCard.ontransitionend = nextImage;
                
            } else if (propX < -0.25 && e.deltaX < 0 && horizontal) {
                // get left border position
                posX = - (elements.board.clientWidth + elements.topCard.clientWidth)
                // throw card towards the left border
                elements.topCard.style.transform =
                'translateX(' + posX + 'px) translateY(' + posY + 'px) rotate(' + deg + 'deg)'
                console.log('PAN event yeet LEFT');
                elements.topCard.ontransitionend = nextImage;
                
            } else if (propY < -0.25 && e.deltaY < 0 && vertical) {                
                // get top border position
                posY = - (elements.board.clientHeight + height)
                // throw card towards the top border
                elements.topCard.style.transform =
                'translateX(' + posX + 'px) translateY(' + posY + 'px) rotate(' + deg + 'deg)'
                console.log('PAN event yeet TOP');
                elements.topCard.ontransitionend = nextImage;
                
            } else {            
                // reset card position
                elements.topCard.style.transform =
                    'translateX(-50%) translateY(-50%) rotate(0deg)'            
            }
          
        }
        
    }
    
    const nextIndex = (index + 1) % assets.length;
    return (
        <div className={classes.board} ref={board} key={index}>
            <ReactHammer 
                options={{
                    recognizers: {
                        pan: {
                            direction: Hammer.DIRECTION_VERTICAL
                        }
                    }
                }}
                onPan={(e) => {
                    e.preventDefault();
                    handleOnPan(e)
                }}
            >
                <div className={classes.deck}>
                    <div key={index} className={classes.current} id="current">
                        <Asset.AsPreview asset={assets[index]} pixelSize={{ width, height }} showControls={true} showAttrs={true}/>
                    </div>
                    <div key={index} className={classes.next} id="next">
                        <Asset.AsPreview asset={assets[nextIndex]} pixelSize={{ width, height }} showControls={true} showAttrs={true}/>
                    </div>
                </div>
            </ReactHammer>
        </div>
    );
  
}