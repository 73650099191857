import * as React from 'react';

import * as Elevated from '@pitaman71/omniglot-introspect';

import { Dialogues, Domains, Modules, Objects, Properties, Relations, Views } from '@pitaman71/omniglot-live-data';

import { Debug } from '@pitaman71/omniglot-live-react';

import genres from 'corpora/data/music/genres.json';

const __moduleName__ = 'swivell.react-ui.Database';

export type ObjectHandle = string;

export namespace Database {
    interface BindingType { database: Objects.Binding<string> }
    export const Descriptor = new class _Descriptor extends Views.Descriptor<BindingType> {
        canonicalName = `${__moduleName__}.Database`;
        build(builder: Views.Builder<BindingType>) {
            builder.object('database');
        }    
    }
} 

export namespace Feed {
    export type TypeParams = {
        Binding: { database: Objects.Binding<string> },
        State: {},
        Direction: void,
        Status: void,
        Control: void
    }

    export const Descriptor = new class _Descriptor extends Dialogues.Descriptor<TypeParams> {
        canonicalName = `${__moduleName__}.Feed`;
        initialize() { return new class {
            binding(default_: Partial<TypeParams["Binding"]>): TypeParams["Binding"] {
                return { database: default_.database || { objectId: '__database__'} };
            }
            state(default_: Partial<TypeParams["State"]>): TypeParams["State"] {
                return {} as TypeParams["State"];
            }
        } }
        enter(entrances: Dialogues.Entrances<TypeParams>) {
        }
        build(builder: Dialogues.Builder<TypeParams>): void {
        }
    }
}

export namespace Match {
    export type TypeParams = {
        Binding: { database: Objects.Binding<string> },
        State: {},
        Direction: void,
        Status: void,
        Control: void
    }

    export const Descriptor = new class _Descriptor extends Dialogues.Descriptor<TypeParams> {
        canonicalName = `${__moduleName__}.Match`;
        initialize() { return new class {
            binding(default_: Partial<TypeParams["Binding"]>): TypeParams["Binding"] {
                return { database: default_.database || { objectId: '__database__'} };
            }
            state(default_: Partial<TypeParams["State"]>): TypeParams["State"] {
                return {} as TypeParams["State"];
            }
        } }
        enter(entrances: Dialogues.Entrances<TypeParams>) {
        }
        build(builder: Dialogues.Builder<TypeParams>): void {
        }
    }
}

export namespace Teams {
    export type TypeParams = {
        Binding: { database: Objects.Binding<string> },
        State: {},
        Direction: void,
        Status: void,
        Control: void
    }

    export const Descriptor = new class _Descriptor extends Dialogues.Descriptor<TypeParams> {
        canonicalName = `${__moduleName__}.Teams`;
        initialize() { return new class {
            binding(default_: Partial<TypeParams["Binding"]>): TypeParams["Binding"] {
                return { database: default_.database || { objectId: '__database__'} };
            }
            state(default_: Partial<TypeParams["State"]>): TypeParams["State"] {
                return {} as TypeParams["State"];
            }
        } }
        enter(entrances: Dialogues.Entrances<TypeParams>) {
        }
        build(builder: Dialogues.Builder<TypeParams>): void {
        }
    }
}

export namespace Root {
    export type TypeParams = {
        Binding: { database: Objects.Binding<string> },
        State: {},
        Direction: 'home'|'teams',
        Status: void,
        Control: void
    }

    export const Descriptor = new class _Descriptor extends Dialogues.Descriptor<TypeParams> {
        canonicalName = `${__moduleName__}.Root`;
        initialize() { return new class {
            binding(default_: Partial<TypeParams["Binding"]>): TypeParams["Binding"] {
                return { database: default_.database || { objectId: '__database__'} };
            }
            state(default_: Partial<TypeParams["State"]>): TypeParams["State"] {
                return {} as TypeParams["State"];
            }
        } }
        enter(entrances: Dialogues.Entrances<TypeParams>) {
        }
        build(builder: Dialogues.Builder<TypeParams>): void {
            builder.route(
                'feed',
                Feed.Descriptor,
                ({binding}) => Feed.Descriptor.bind(binding)
            )
            builder.route(
                'match',
                Match.Descriptor,
                ({binding}) => Match.Descriptor.bind(binding)
            )
            builder.route(
                'teams',
                Teams.Descriptor,
                ({binding}) => Teams.Descriptor.bind(binding)
            )
        }    
    }
}

export const DescriptorsContext = React.createContext<{ dialogues: Dialogues.Descriptor<any>[] }>({ dialogues: [] });

export function Provide(props: {
    children: JSX.Element[]|JSX.Element
}) {
    return (
        <DescriptorsContext.Provider value={{ dialogues: [
            Feed.Descriptor,
            Match.Descriptor,
            Teams.Descriptor,
            Root.Descriptor,
            Modules.Profile.SetupYourProfile.Descriptor,
            Modules.Media.ManageAsset.Descriptor
        ]}}>
            {props.children}
        </DescriptorsContext.Provider>
    )
}
