import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';

import * as XLayout from '@pitaman71/react-explicit-layout'
import * as MuiIcons from '@material-ui/icons';
import * as NounProject from './NounProject';

interface Config {
    pixelSize: {
        width: number,
        height: number
    },
    image?: {
        url: string
    },
    video?: {
        url: string
    },
    handle?: string,
    title?: string
}

export function Attributes(props: {
    asset: Config
}) {
    const useStyles = makeStyles((theme) => ({
        root: {
          backgroundColor: 'transparent',
          flexDirection: 'column'
        },
    }));
    const styles=useStyles();
      
    return (
        <XLayout.Partition top={0.8} bottom={1} left={0.1} right={0.6}>
            <table>
            <tbody>
            <tr><td style={{ textAlign: 'left'}}>{props.asset.handle ? (<b>{props.asset.handle}</b>) : <React.Fragment/> }</td></tr>
            <tr><td style={{ textAlign: 'left'}}>{props.asset.title ? (<i>{props.asset.title}</i>) : <React.Fragment/> }</td></tr>
            </tbody>
            </table>
        </XLayout.Partition>
    )
}

export function Controls(props: {
}) {
    const useStyles = makeStyles((theme) => ({
        root: {
          backgroundColor: 'transparent',
          flexDirection: 'column'
        },
    }));
    const styles=useStyles();
      
    const specs = [
        {
            label: 'Like',
            icon: () => <NounProject.Like color="white"/>
        },
        {
            label: 'Invite',
            icon: () => <NounProject.WavingHand color="white"/>
        },
        {
            label: 'Pin',
            icon: () => <NounProject.Pin color="white"/>
        },
        {
            label: 'Comment',
            icon: () => <NounProject.Comment color="white"/>
        },
    ]
    return (
        <XLayout.Partition top={0.7} bottom={0.9} left={0.85} right={1.0}>
            <BottomNavigation className={styles.root} showLabels>
            {
                specs.map((spec, index) => (
                    <BottomNavigationAction label={spec.label} icon={spec.icon()}/>
                ))
            }
            </BottomNavigation>
        </XLayout.Partition>
    )
}

export function AsPreview(props: {
    pixelSize: {
        width: number,
        height: number
    },
    asset: Config,
    showControls: boolean,
    showAttrs: boolean
}) {
    const viewRatio = props.pixelSize.width / props.pixelSize.height;
    const imageRatio = props.asset.pixelSize.width / props.asset.pixelSize.height;

    const byWidth = (viewRatio: number, imageRatio: number) => {
        // zoom width to match
        // crop height to match
        const scale = props.pixelSize.width / props.asset.pixelSize.width;
        const width = props.pixelSize.width;
        const height = props.asset.pixelSize.height * scale;
        const left = 0;
        const top = -(height - props.pixelSize.height) / 2;
        return { width, height, left, top};
    };

    const byHeight = (viewRatio: number, imageRatio: number) => {
        // zoom height to match
        // crop width to match
        const scale = props.pixelSize.height / props.asset.pixelSize.height;
        const height = props.pixelSize.height;
        const width = props.asset.pixelSize.width * scale;
        const left = -(width - props.pixelSize.width) / 2;
        const top = 0;
        return { width, height, left, top};
    };

    const params = viewRatio > imageRatio
        ? byWidth(viewRatio, imageRatio)
        : byHeight(viewRatio, imageRatio);

    if(props.asset.image) {
        return (
            <XLayout.Frame>
                <XLayout.Layer>
                    <img style={{ position: 'relative', ...params }} src={props.asset.image.url}/>
                </XLayout.Layer>
                <XLayout.Layer>
                    { props.showControls ? (<Controls></Controls>) : [] }
                </XLayout.Layer>
                <XLayout.Layer>
                    { props.showAttrs ? (<Attributes { ...props}/>) : [] }
                </XLayout.Layer>
            </XLayout.Frame>
        )
    } else if(props.asset.video) {
        return (
            <XLayout.Frame>
                <XLayout.Layer>
                    <video style={{ position: 'relative', ...params }} src={props.asset.video.url}/>
                </XLayout.Layer>
                <XLayout.Layer>
                    { props.showControls ? (<Controls></Controls>) : []}
                </XLayout.Layer>
                <XLayout.Layer>
                    { props.showAttrs ? (<Attributes { ...props}/>) : [] }
                </XLayout.Layer>
            </XLayout.Frame>
        )
    } else {
        return (
            <XLayout.Frame>
                <XLayout.Layer>
                    <XLayout.Center>
                        <MuiIcons.BrokenImage/>
                    </XLayout.Center>
                </XLayout.Layer>
            </XLayout.Frame>
        )
    }
}
